import Cookies from 'js-cookie'

$(document).ready(function () {
  //replace img tag to inline svg
  //good to change colors
  $('img.svg').each(function () {
    var $img = $(this)
    var imgID = $img.attr('id')
    var imgClass = $img.attr('class')
    var imgURL = $img.attr('src')

    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg')

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID)
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass + ' replaced-svg')
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a')

        // Replace image with new SVG
        $img.replaceWith($svg)
      },
      'xml'
    )
  })

  const hasAgreed = getCookie()

  if (!!hasAgreed) {
    $('.cookies').addClass('hide')
  }

  $('#cookies').click(function () {
    setCookie()
    $('.cookies').addClass('hide')
  })

  $('nav').before($('.navbar').clone().addClass('fixed'))

  $('.navbar--hamburger').click(function () {
    if ($('.navbar').hasClass('is-active')) {
      $('.navbar').removeClass('is-active')
      $('body').css('overflow', '')
    } else {
      $('.navbar').addClass('is-active')
      $('body').css('overflow', 'hidden')
    }
  })

  $('.rich-text a').each(function () {
    const $link = $(this)
    $link.attr('target', '_blank')
    $link.attr('rel', 'noopener')
  })

  $(document).scroll(navbar)
  navbar()

  $('.navbar--search').each(function () {
    const $link = $(this)
    $link.click(function (e) {
      e.preventDefault()

      $('.search-modal').modal({
        fadeDuration: 150,
        fadeDelay: 0.3,
        showClose: false,
        modalClass: 'modal search-modal',
        blockerClass: 'search-blocker',
      })

      $('.search-modal').on($.modal.OPEN, function(event) {
        $('.search-modal--text').focus()
      });
    })
  })

  $('[class$="--close"]').click(function (e) {
    $.modal.close()
  })

  $('form[name="global-search"]').on('submit', function (e) {
    e.preventDefault()
    const params = $(
      `form[name=${$(this).attr(
        'name'
      )}]  input:not([disabled]):not([type="submit"])`
    )
      .filter(function (index, element) {
        return $(element).val() != ''
      })
      .serialize()
    const search_url = $(this).attr('data-url')
    window.location.replace(`${search_url}?${params}`)
  })
})

function navbar() {
  if ($(document).scrollTop() >= 50) {
    $('.navbar.fixed').addClass('slideDown')
  } else {
    $('.navbar.fixed').removeClass('slideDown')
  }
}

function isMobile() {
  return window.matchMedia('only screen and (max-width: 768px)').matches
}

function getCookie() {
  return Cookies.get('cookie-agreement')
}

function setCookie() {
  Cookies.set('cookie-agreement', true, { sameSite: 'strict' })
}
